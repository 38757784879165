/*---- Tags-----*/

.tag {
	font-size: 0.75rem;
    color: #14112d;
    background-color: $gray-100;
    border-radius: 3px;
	padding: 0 .5rem;
	line-height: 2em;
	display: -ms-inline-flexbox;
	display: inline-flex;
	cursor: default;
	font-weight: 400;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

a.tag {
  text-decoration: none;
  cursor: pointer;
  transition: .3s color, .3s background;

  &:hover {
    background-color: rgba(110, 118, 135, 0.2);
    color: inherit;
  }
}

.tag-addon {
  display: inline-block;
  padding: 0 .5rem;
  color: inherit;
  text-decoration: none;
  background: rgba(0, 0, 0, 0.06);
  margin: 0 -.5rem 0 .5rem;
  text-align: center;
  min-width: 1.5rem;

  &:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  i {
    vertical-align: middle;
    margin: 0 -.25rem;
  }
}

a.tag-addon {
  text-decoration: none;
  cursor: pointer;
  transition: .3s color, .3s background;

  &:hover {
    background: rgba(0, 0, 0, 0.16);
    color: inherit;
  }
}

.tag-avatar {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 3px 0 0 3px;
  margin: 0 .5rem 0 -.5rem;
}

.tag-blue {
  background-color: #467fcf !important;
  color: $white;
}

.tag-indigo {
  background-color: $primary !important;
  color: $white;
}

.tag-purple {
  background-color: #8500ff !important;
  color: $white;
}

.tag-pink {
  background-color: #ec82ef !important;
  color: $white;
}

.tag-red {
  background-color: #ec2d38 !important;
  color: $white;
}

.tag-orange {
  background-color: $orange !important;
  color: $white;
}

.tag-yellow {
  background-color: #fdb901 !important;
  color: $white;
}

.tag-green {
  background-color: #0fa751 !important;
  color: $white;
}

.tag-teal {
  background-color: $teal !important;
  color: $white;
}

.tag-cyan {
  background-color: $info !important;
  color: $white;
}

.tag-white {
  background-color: $white !important;
  color: $white;
}

.tag-gray {
  background-color: #868e96 !important;
  color: $white;
}

.tag-gray-dark {
  background-color: #343a40 !important;
  color: $white;
}

.tag-azure {
  background-color: #17c1f4 !important;
  color: $white;
}

.tag-lime {
  background-color: #7bd235 !important;
  color: $white;
}

.tag-primary {
  background-color: #467fcf !important;
  color: $white;
}

.tag-secondary {
  background-color: #868e96 !important;
  color: $white;
}

.tag-success {
  background-color: #0fa751 !important;
  color: $white;
}

.tag-info {
  background-color: #17c1f4 !important;
  color: $white;
}

.tag-warning {
  background-color: #fdb901 !important;
  color: $white;
}

.tag-danger {
  background-color: #ec2d38 !important;
  color: $white;
}

.tag-light {
  background-color: #f8f9fa !important;
  color: $white;
}

.tag-dark {
  background-color: #343a40 !important;
  color: $white;
}

.tag-rounded {
  border-radius: 50px;

  .tag-avatar {
    border-radius: 50px;
  }
}

.tags {
  margin-bottom: -.5rem;
  font-size: 0;

  > .tag {
    margin-bottom: .5rem;

    &:not(:last-child) {
      margin-right: .5rem;
    }
  }
}

@media (min-width: 459px) {
  .text-wrap .example .form-group{
    margin-bottom:0;
  }
}